import { Movie } from '../shared/movies';
import { RoomConfig } from '../shared/messages';
import firebase from 'firebase/app';
import { firestore } from './firebase';

type AuthedArgs = {
  token: string;
};

const DEFAULT_URL = 'https://api.cinemaswipe.com';
const UserPrefRef = firestore.collection('ABTest');

async function GetHostURL(): Promise<string> {
  const currentUser = firebase.auth().currentUser;
  if(!currentUser) return DEFAULT_URL;
  const doc = await UserPrefRef.doc(currentUser.uid).get();
  const data = doc.data();
  return data?.ApiUrl ?? DEFAULT_URL;
}

async function GetMovieById(id: number): Promise<Movie> {
  const resp = await fetch(`${await GetHostURL()}/movie/${id}`);
  const json = await resp.json();
  return json;
}

type RoomResponse = {
  code: string;
};

async function CreateRoom(
  config: RoomConfig,
  { token }: AuthedArgs
): Promise<RoomResponse> {
  const resp = await fetch(`${await GetHostURL()}/createroom`, {
    headers: { authorization: token, 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(config)
  });
  if (resp.ok) {
    return { code: (await resp.json()).code };
  }
  throw new Error(await resp.text());
}

async function RecordClickthrough(
  roomCode: string,
  movieId: number,
  { token }: AuthedArgs
) {
  const resp = await fetch(`${await GetHostURL()}/clicked`, {
    method: 'POST',
    headers: {
      authorization: token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ movie: { id: movieId }, roomCode })
  });
  return await resp.json();
}

export const API = {
  CreateRoom,
  GetMovieById,
  RecordClickthrough,
  GetHostURL,
};
