import React, { useEffect, useState } from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableWithoutFeedback,
  Animated,
  Image,
  GestureResponderEvent,
  Pressable,
  Platform,
} from 'react-native';
import layout from '../constants/Layout';
import { Movie } from '../shared';
const { width, height } = layout.window;
interface MovieCardProps {
  card: Movie;
  forceCache?: boolean;
  index?: number;
  tapped: boolean;
  focused: boolean;
}

function MovieCard({
  card,
  forceCache,
  index,
  tapped,
  focused
}: MovieCardProps) {
  const { title, description, image_url, mpaa_rating } = card;

  const [rotatedAmount, setRotatedAmount] = useState(0);
  const [rotation] = useState(new Animated.Value(0));
  const [flipped, setFlipped] = useState(false);
  useEffect(() => {
    if (flipped !== tapped) {
      setFlipped(tapped);
      flip();
    }
  }, [flipped, tapped, setFlipped]);

  rotation.addListener((l) => setRotatedAmount(l.value));

  const frontInterpolate = rotation.interpolate({
    inputRange: [0, 180],
    outputRange: ['0deg', '180deg']
  });
  const backInterpolate = rotation.interpolate({
    inputRange: [0, 180],
    outputRange: ['180deg', '360deg']
  });

  const flip = () => {
    if (rotatedAmount < 90) {
      Animated.spring(rotation, {
        toValue: 180,
        tension: 40,
        friction: 7,
        useNativeDriver: true
      }).start();
    } else {
      Animated.spring(rotation, {
        toValue: 0,
        tension: 40,
        friction: 7,
        useNativeDriver: true
      }).start();
    }
  };
  const frontStyle = {
    transform: [{ rotateY: frontInterpolate }]
  };
  const backStyle = {
    transform: [{ rotateY: backInterpolate }]
  };
  const [skew] = useState(index ? (index % 2 == 0 ? -1 : 1) : 0);
  return (
      <View
        style={[styles.cardWrapper, { transform: [{ rotateZ: `${skew}deg` }] }]}
      >
        <Animated.View style={[frontStyle, styles.cardFront, styles.card]}>
          <View style={styles.cardImgWrapper}>
            <Image
              source={
                image_url
                  ? {
                      uri: image_url,
                      ...(forceCache && { cache: 'force-cache' })
                    }
                  : require('../assets/images/Image-Not-Available.png')
              }
              style={[styles.cardImg]}
            />
          </View>
          <View style={styles.cardCaption}>
            <Text style={styles.cardTitle}>{title}</Text>
          </View>
        </Animated.View>
        <Animated.View style={[backStyle, styles.cardBack, styles.card]}>
          <View style={styles.cardCaption}>
            <Text style={styles.cardTitle}>{title}</Text>
          </View>
          <Text selectable={false} style={styles.description}>
            {description}
          </Text>
          {card.runtime && (
            <Text style={styles.description}>{card.runtime}</Text>
          )}
          {card.release_year && (
            <Text style={styles.description}>
              Released in {card.release_year}
            </Text>
          )}
          {card.mpaa_rating && (
            <Text style={styles.description}>{card.mpaa_rating}</Text>
          )}
        </Animated.View>
      </View>
  );
}

export default MovieCard;

const styles = StyleSheet.create({
  cardFront: {
    backfaceVisibility: 'hidden',
    height: '100%'
  },
  cardBack: {
    position: 'absolute',
    top: 0,
    height: '100%',
    backfaceVisibility: 'hidden'
  },
  cardWrapper: {
    marginTop: 0,
    margin: Platform.OS === 'web' ? 'auto' : undefined,
    marginLeft: Platform.OS === "web" ? undefined : (width - height * 0.7 * 0.6)/2,
    justifyContent: 'center',
    maxHeight: height * 0.7,
    maxWidth: '100%',
    width: height * 0.7 * 0.6,
    flex: 1,
    flexDirection: 'column',
  },
  card: {
    marginTop: 0,
    margin: 'auto',
    justifyContent: 'center',
    backgroundColor: 'white',
    maxHeight: '100%',
    width: '100%',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    flex: 1,
    flexDirection: 'column'
  },
  cardImgWrapper: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'black',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  cardImg: {
    resizeMode: 'cover',
    width: '100%',
    backgroundColor: 'white',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    flex: 1
  },
  description: {
    padding: 10
  },
  cardCaption: {
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%'
  },
  cardTitle: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 20,
    textTransform: 'capitalize',
    width: '90%'
  }
});
