import React, { useState } from 'react';
import {
  StyleSheet,
  View,
  Text,
  Modal,
  TouchableWithoutFeedback,
  Platform
} from 'react-native';

import HeaderButton from './HeaderButton';
import theme from '../style';
import { SafeAreaView } from 'react-native-safe-area-context';
export interface ModalAlertProps {
  title: string;
  subtext?: string;
  options: {
    label: string;
    onPress?: Function;
  }[];
  onDismiss?: Function;
}

function ModalAlert({ title, subtext, options, onDismiss }: ModalAlertProps) {
  return (
    <SafeAreaView style={{ width: '100%' }}>
      <View style={theme.centerScreen}>
        <Modal
          visible={true}
          animationType='fade'
          transparent={true}
          onRequestClose={() => onDismiss && onDismiss()}
          style={{ zIndex: 1, position: 'absolute' }}
        >
          <TouchableWithoutFeedback onPress={() => onDismiss && onDismiss()}>
            <View style={theme.modalOverlay}>
              <View style={[theme.centeredView, {}]}>
                <TouchableWithoutFeedback>
                  <View style={theme.modalView}>
                    <Text style={theme.modalText}>{title}</Text>
                    <Text style={styles.modalSubText}>{subtext || ''}</Text>
                    <View style={theme.modalButtons}>
                      {options.map((o, i) => (
                        <HeaderButton
                          label={o.label}
                          key={i}
                          style={[
                            theme.modalButton,
                            {
                              borderRightWidth: 0.5,
                              width: `${100 / options.length}%`
                            }
                          ]}
                          onPress={() => {
                            o.onPress && o.onPress();
                          }}
                        />
                      ))}
                    </View>
                  </View>
                </TouchableWithoutFeedback>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      </View>
    </SafeAreaView>
  );
}

export default ModalAlert;

const styles = StyleSheet.create({
  modalSubText: {
    alignSelf: 'center',
    textAlign: 'center',
    padding: 10,
    paddingTop: 0
  }
});
