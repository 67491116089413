import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  Image,
  Platform,
  SafeAreaView,
  StyleSheet,
  View
} from 'react-native';
import Swiper from 'react-native-deck-swiper';

import IconButton from '../components/IconButton';
import ModalAlertButton from '../components/ModalAlertButton';
import MovieCard from '../components/MovieCard';
import Layout from '../constants/Layout';
import { Movie } from '../shared/movies';
import { API } from '../storage/Api';
import { MovieQueue } from '../storage/MovieQueue';
import { useSocket } from '../storage/SocketHook';
import { ClientSocket } from '../storage/SwipeSocket';
import theme from '../style/index';
import { AppScreens, RootStackParamList } from '../types';

type SwipeRouteProps = RouteProp<RootStackParamList, AppScreens.Swipe>;
type SwipeScreenNavProps = StackNavigationProp<
  RootStackParamList,
  AppScreens.Swipe
>;

interface SwipeScreenProps {
  navigation: SwipeScreenNavProps;
  route: SwipeRouteProps;
}
const STACK_SIZE = 4;

function SwipeScreen({ navigation, route }: SwipeScreenProps) {
  const { roomCode, displayName } = route.params;
  const [socket, setSocket] = useState<ClientSocket | null>(null);
  const socketWrapper = useSocket();
  useEffect(() => {
    socketWrapper
      .JoinRoom(roomCode, displayName)
      .then((s) => setSocket(s))
      .catch((err) => {
        console.error(err);
        navigation.popToTop();
        navigation.replace(AppScreens.Home);
      });
  }, [setSocket, socketWrapper, navigation]);
  if (socket) {
    return <SwipeScreenComponent socket={socket} navigation={navigation} />;
  } else {
    return (
      <View style={theme.centerScreen}>
        <ActivityIndicator size='large' />
      </View>
    );
  }
}
interface SwipeScreenComponentProps {
  socket: ClientSocket;
  navigation: SwipeScreenNavProps;
}
function SwipeScreenComponent({
  socket,
  navigation
}: SwipeScreenComponentProps) {
  const [movieStack, setMovieStack] = useState<Movie[]>([]);
  const [head, setHead] = useState(0);
  const [tail, setTail] = useState(0);
  const [movieQueue] = useState(new MovieQueue());
  const [loading, setLoading] = useState<boolean>(true);
  const [tapped, setTapped] = useState(false);

  useEffect(() => {
    const dispose = socket.OnMatch((id) => {
      API.GetMovieById(id).then((movie) => {
        socket.Disconnect();
        navigation.replace(AppScreens.Matches, {
          movie,
          roomCode: socket.roomCode
        });
      });
    });
    return dispose;
  }, [navigation, socket]);

  useEffect(() => {
    const disponse = socket.OnDisconnect(() => {
      // navigation.popToTop();
      // navigation.replace(AppScreens.Home);
      setLoading(true);
    });
    return disponse;
  }, [navigation, socket]);

  useEffect(() => {
    const dispose = socket.OnConnected(() => {
      setLoading(false);
    });
    return dispose;
  });
  useEffect(() => {
    const dispose = socket.OnMoviesQueued((m, pos) => {
      console.log('Received movies');
      console.log(m);
      if (pos === 'front') movieQueue.PushFront(m);
      else movieQueue.Push(m);
    });
    return dispose;
  }, [socket, movieQueue]);

  useEffect(() => {
    socket.RequestMoreMovies().then((movies) => {
      movieQueue.Push(movies);
      const newMovies = movieQueue.Pop(STACK_SIZE);
      setMovieStack(newMovies);
      setTail(newMovies.length % STACK_SIZE);
      setLoading(false);
    });
  }, [socket, setMovieStack, setTail, movieQueue]);

  const leaveRoom = () => {
    socket.Disconnect();
    navigation.popToTop();
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeftContainerStyle: { width: '100%' },
      headerLeft: () => {
        return (
          <ModalAlertButton
            title={socket.IsOwner ? 'Close Room' : 'Leave Room'}
            modal={{
              title: socket.IsOwner ? 'Close Room' : 'Leave Room',

              options: [{ label: 'Yes', onPress: leaveRoom }, { label: 'No' }],
              subtext: socket.IsOwner
                ? 'This will close the room for all members'
                : 'Leaving will bring you to the Home Page'
            }}
          />
        );
      }
    });
  }, [navigation]);

  // Swiper logic
  const useSwiper = useRef<any>(null);
  const swipeLeft = () => useSwiper.current.swipeLeft();
  const swipeRight = () => useSwiper.current.swipeRight();
  const icons = [
    {
      name: 'close',
      color: 'red',
      size: 24,
      onPress: swipeLeft
    },
    {
      name: 'heart',
      color: 'green',
      size: 24,
      onPress: swipeRight
    }
  ];
  useEffect(() => {
    if (Platform.OS === 'web') {
      const handleKeyPress = (ev: KeyboardEvent) => {
        if (!loading) {
          if (ev.key === 'ArrowLeft') swipeLeft();
          if (ev.key === 'ArrowRight') swipeRight();
          if (ev.code === 'Space') {
            setTapped((t) => !t);
            ev.preventDefault();
            return false;
          }
        }
      };
      document.addEventListener('keydown', handleKeyPress);
      return () => document.removeEventListener('keydown', handleKeyPress);
    }
  }, [swipeLeft, swipeRight, setTapped]);

  const handleSwipeLeft = (cardIndex: number) => {
    socket.Swipe(movieStack[cardIndex], 'left');
    handleSwipe(cardIndex);
  };

  const handleSwipeRight = (cardIndex: number) => {
    socket.Swipe(movieStack[cardIndex], 'right');
    handleSwipe(cardIndex);
  };

  const handleSwipe = async (index: number) => {
    setTapped(false);
    const newHead = (index + 1) % STACK_SIZE;
    if (movieQueue.Length() < 3) {
      console.log('Fetching more movies');
      socket
        .RequestMoreMovies()
        .then((m) => {
          m.forEach((movie) => Image.prefetch(movie.image_url));
          movieQueue.Push(m);
        })
        .catch(console.error);
    }

    let t = tail;
    let m = movieStack;
    while (t != newHead) {
      const movie = movieQueue.Pop();
      if (!movie) {
        m[t] = {
          // Temp loading movie
          content_type: 'tv',
          description: 'Loading',
          title: 'pending',
          id: 0,
          platforms: [],
          mpaa_rating_filter: 'G',
          genres: [],
          image_url: ''
        };
        break;
      }
      m[t] = movie;
      t = (t + 1) % STACK_SIZE;
    }
    if (m[newHead].id === 0) {
      setLoading(true);
      console.log('Waiting for more movies...');
      movieQueue.PopAsync(STACK_SIZE).then((movies) => {
        setHead(0);
        setTail(movies.length % STACK_SIZE);
        setMovieStack((m) => {
          movies.forEach((movie, i) => (m[i] = movie));
          return [...m];
        });
        setLoading(false);
      });
    }
    setMovieStack([...m]);
    setHead(newHead);
    setTail(t);
  };

  const cards = movieStack.map((card, i) => {
    return (
      <MovieCard
        card={card}
        forceCache={true}
        index={0}
        tapped={tapped && i === head}
        focused={i === head}
      />
    );
  });

  return (
    <SafeAreaView style={theme.fullScreen}>
      {!loading ? (
        <View style={{ flex: 1, alignItems: 'center' }}>
          <Swiper
            cardStyle={{
              position: 'absolute',
              left: 0,
              width: Layout.window.width
            }}
            ref={useSwiper}
            animateCardOpacity={false} // todo: figure out how to allow this to be true (can't have the other items with opacity = 0 after rendering)
            cards={cards}
            renderCard={(card) => card}
            backgroundColor='#f5f5f5'
            cardIndex={head}
            stackSize={3}
            infinite
            showSecondCard
            disableBottomSwipe
            disableTopSwipe
            onTapCard={() => setTapped((t) => !t)}
            onSwipedRight={handleSwipeRight}
            onSwipedLeft={handleSwipeLeft}
            cardVerticalMargin={30}
            cardHorizontalMargin={50}
            stackScale={1}
            stackSeparation={1}
          />
          <View style={styles.buttons}>
            {icons.map((icon, index) => {
              return <IconButton key={index} button={icon} />;
            })}
          </View>
        </View>
      ) : (
        <ActivityIndicator size='large' />
      )}
    </SafeAreaView>
  );
}

export default SwipeScreen;

const styles = StyleSheet.create({
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    position: 'absolute',
    bottom: 25,
    width: '100%',
    flex: 1
  }
});
