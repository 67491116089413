import React, { useState, useEffect } from 'react';
import { Linking, Platform } from 'react-native';
import AppLoading from 'expo-app-loading';
import cacheAssets from './utils/cacheAssetsAsync';
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';

import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { RootStackParamList, AppScreens, User } from './types';

import HomeScreen from './screens/HomeScreen';
import SwipeScreen from './screens/SwipeScreen';
import WaitingRoom from './screens/WaitingRoom';
import ConfigScreen from './screens/ConfigScreen';
import MatchesScreen from './screens/MatchesScreen';
import { Image, Text, View } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { TouchableOpacity } from 'react-native-gesture-handler';
import theme from './style';
import { UserProvider } from './storage/UserHook';
import SettingsScreen from './screens/SettingsScreen';
import { SocketProvider } from './storage/SocketHook';
import { auth } from './storage/firebase';
import { SignInWithApple } from './components/SignInWithApple';

const Stack = createStackNavigator<RootStackParamList>();
const PERSISTENCE_KEY = 'NAVIGATION_STATE';

function MainStack(props: { state: any }) {
  console.log('Main stack!');
  return (
    <NavigationContainer
      initialState={props.state}
      onStateChange={(state) => {
        console.log(JSON.stringify(state));
        AsyncStorage.setItem(PERSISTENCE_KEY, JSON.stringify(state));
      }}
      linking={{
        prefixes: ['http://localhost:19006', 'https://api.cinemaswipe.com'],
        config: {
          screens: {
            [AppScreens.Home]: '',
            [AppScreens.Config]: 'roomconfiguration',
            [AppScreens.Waiting]: 'waiting',
            [AppScreens.Settings]: 'settings',
            [AppScreens.Swipe]: 'swipe',
            [AppScreens.Matches]: {
              path: 'match/:movie',
              parse: {
                movie: (movie) => JSON.parse(decodeURIComponent(movie))
              },
              stringify: {
                movie: (movie) => JSON.stringify(movie)
              }
            }
          }
        }
      }}
    >
      <Stack.Navigator initialRouteName={AppScreens.Home}>
        <Stack.Screen
          name={AppScreens.Home}
          component={HomeScreen}
          options={{
            headerTitle: 'Cinema Swipe',
            headerTitleAlign: 'center'
          }}
        />
        <Stack.Screen
          name={AppScreens.Settings}
          component={SettingsScreen}
          options={{ headerTitleAlign: 'center' }}
        />
        <Stack.Screen
          name={AppScreens.Waiting}
          component={WaitingRoom}
          options={{
            headerTitle: ''
          }}
        />
        <Stack.Screen
          name={AppScreens.Config}
          component={ConfigScreen}
          options={{
            headerTitle: 'Room Settings',
            headerTitleAlign: 'center'
          }}
        />
        <Stack.Screen
          name={AppScreens.Swipe}
          component={SwipeScreen}
          options={{
            headerTitle: ''
          }}
        />
        <Stack.Screen
          name={AppScreens.Matches}
          component={MatchesScreen}
          options={{
            headerTitle: 'Match Found',
            headerTitleAlign: 'center'
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

WebBrowser.maybeCompleteAuthSession();
export default function App() {
  const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
    expoClientId:
      '252746202953-blhe76s165h1pk39gi83scbeqdbatmmq.apps.googleusercontent.com',
    webClientId:
      '252746202953-blhe76s165h1pk39gi83scbeqdbatmmq.apps.googleusercontent.com',
    iosClientId:
      '252746202953-sanidkqs271jj5un4vkjmpm17pfnpno8.apps.googleusercontent.com'
  });
  useEffect(() => {
    if (response?.type === 'success') {
      const { id_token } = response.params;

      const credential = auth.GoogleAuthProvider.credential(id_token);
      auth().signInWithCredential(credential);
    }
  }, [response]);

  const [ready, setReady] = useState(false);
  const [initialState, setInitialState] = useState<any | undefined>();
  const [user, setUser] = useState<User | null | undefined>(undefined);

  useEffect(() => {
    const dispose = auth().onAuthStateChanged((newUser) => setUser(newUser));
    return () => dispose();
  }, [setUser]);

  useEffect(() => {
    const restoreState = async () => {
      try {
        const initialUrl = await Linking.getInitialURL();

        if (Platform.OS !== 'web' && initialUrl == null) {
          // Only restore state if there's no deep link and we're not on web
          const savedStateString = await AsyncStorage.getItem(PERSISTENCE_KEY);
          const state = savedStateString
            ? JSON.parse(savedStateString)
            : undefined;
          console.log(state);
          if (state !== undefined) {
            setInitialState(state);
          }
        }
      } finally {
        setReady(true);
      }
    };

    if (!ready) {
      restoreState();
    }
  }, [ready]);
  const prepare = async () => {
    await cacheAssets({
      images: [
        require('./assets/images/netflix-wide.png'),
        require('./assets/images/hulu-wide.png'),
        require('./assets/images/disneyplus-wide.jpg'),
        require('./assets/images/hbonow-wide.png'),
        require('./assets/login/sign-in-with-google.png'),
        require('./assets/icon.png')
      ]
    });
  };

  if (!ready || user === undefined) {
    return (
      <AppLoading
        startAsync={prepare}
        onFinish={() => {
          console.log('Finished loading');
        }}
        onError={console.error}
      />
    );
  }
  if (user) {
    return (
      <UserProvider user={user}>
        <SocketProvider user={user}>
          <MainStack state={initialState} />
        </SocketProvider>
      </UserProvider>
    );
  }
  return (
    <View style={theme.centerScreen}>
      <Image
        source={require('./assets/icon.png')}
        style={{ width: 200, height: 200, marginBottom: 100 }}
      />
      <TouchableOpacity style={theme.button} onPress={() => promptAsync()}>
        <Image
          source={require('./assets/login/sign-in-with-google.png')}
          style={{ width: 200, height: 48 }}
        />
      </TouchableOpacity>
      <SignInWithApple/>
      <TouchableOpacity
        style={theme.button}
        onPress={() => auth().signInAnonymously()}
      >
        <Text>Skip for now</Text>
      </TouchableOpacity>
    </View>
  );
}
