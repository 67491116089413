import { useState } from 'react';
import HeaderButton from './HeaderButton';
import ModalAlert, { ModalAlertProps } from './ModalAlert';

type ModalAlertButtonProps = {
  title: string;
  modal: ModalAlertProps;
};
export default function ModalAlertButton({
  title,
  modal
}: ModalAlertButtonProps) {
  const [isDisplayed, setIsDisplayed] = useState(false);
  return (
    <>
      {isDisplayed && (
        <ModalAlert
          title={modal.title}
          subtext={modal.subtext}
          options={modal.options.map((o) => ({
            label: o.label,
            onPress: () => {
              setIsDisplayed(false);
              o.onPress && o.onPress();
            }
          }))}
          onDismiss={() => setIsDisplayed(false)}
        />
      )}
      <HeaderButton label={title} onPress={() => setIsDisplayed(true)} />
    </>
  );
}
