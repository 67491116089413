import { Movie } from './shared/movies';
import firebase from 'firebase/app';
export enum AppScreens {
  Home = 'Home',
  Swipe = 'Swipe',
  Config = 'Config',
  Waiting = 'WaitingRoom',
  Matches = 'Matches',
  Settings = 'Settings'
}

export type RootStackParamList = {
  Home: undefined;
  Swipe: { roomCode: string; displayName: string };
  WaitingRoom: {
    roomCode: string;
    displayName: string;
  };
  Config: { displayName: string };
  Matches: { movie: Movie; roomCode: string };
  Settings: undefined;
};

export type MovieCard = {
  title: string;
  desc: string;
  img?: string;
  MPAA?: string;
  platforms?: { name: string; url: string }[];
};

export type User = firebase.User;
