import React from 'react';
import { StyleSheet, View } from 'react-native';

type BadgePropType = {style?: any, children: any, component?: any}

function Badge({ children, style, component, ...props }: BadgePropType) {
  const Component = component || View;

  return (
    <Component
      style={style ? [styles.badge, ...style] : styles.badge}
      {...props}
    >
      {children}
    </Component>
  );
}

export default Badge;

const styles = StyleSheet.create({
  badge: {
    margin: 5,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    backgroundColor: 'white'
  }
});
