import { EvilIcons } from '@expo/vector-icons';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  Modal,
  Platform, SafeAreaView, StyleSheet, Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback, View
} from 'react-native';

import { StackNavigationProp } from '@react-navigation/stack';
import theme from '../style/index';
import { AppScreens, RootStackParamList } from '../types';

import HeaderButton from '../components/HeaderButton';
import { Prefs } from '../storage/Preferences';
import { useSocket } from '../storage/SocketHook';
import ModalAlert from '../components/ModalAlert';

type HomeScreenNavProps = StackNavigationProp<
  RootStackParamList,
  AppScreens.Home
>;

interface HomeScreenProps {
  navigation: HomeScreenNavProps;
}

function HomeScreen({ navigation }: HomeScreenProps) {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalType, setModalType] = useState<'Create' | 'Join'>('Join');
  const [displayName, setDisplayName] = useState<string>('');
  const [roomCode, setRoomCode] = useState('');
  const [error, setError] = useState<string | null>(null);
  const socket = useSocket();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRightContainerStyle: { position: 'absolute' },
      headerRight: () => {
        return (
          <EvilIcons
            name='gear'
            color='#007bff'
            size={24}
            style={{ position: 'absolute', right: 5 }}
            onPress={() => {
              navigation.push(AppScreens.Settings);
            }}
          />
        );
      }
    });
  }, [navigation]);

  useEffect(() => {
    Prefs.DisplayName.get()
      .then((name) => {
        name && setDisplayName(name);
      })
      .catch(console.error);
  }, [setDisplayName]);

  const handleCreateRoom = async () => {
    if (!IsValidDisplayName(displayName)) {
      setError('You Must Set a Display Name');
      return;
    }
    Prefs.DisplayName.set(displayName);
    setModalVisible(false);
    navigation.navigate(AppScreens.Config, {
      displayName
    });
  };

  const handleJoinRoom = async () => {
    if (!IsValidDisplayName(displayName)) {
      setError('You Must Set a Display Name');
      return;
    }
    if (roomCode.length === 0) {
      setError('Enter a Valid Room Code');
      return;
    }
    Prefs.DisplayName.set(displayName);
    try {
      await socket.JoinRoom(roomCode, displayName);
      setModalVisible(false);
      navigation.navigate(AppScreens.Waiting, { displayName, roomCode });
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        console.error('Unknown error occurred: ', err);
      }
    }
  };

  const pressCreate = async () => {
    setModalType('Create');
    setModalVisible(true);
  };
  const pressJoin = async () => {
    setModalType('Join');
    setModalVisible(true);
  };

  return (
    <SafeAreaView style={theme.centerScreen}>
      {(modalVisible || Platform.OS !== 'web') && (
        <Modal
          visible={modalVisible}
          animationType='fade'
          transparent={true}
          onRequestClose={() => {
            setModalVisible(false);
          }}
          style={{ zIndex: 1, position: 'absolute' }}
        >
          <TouchableWithoutFeedback
            onPress={() => {
              if (modalVisible) setModalVisible(false);
            }}
          >
            <View style={theme.modalOverlay}>
              <View style={theme.centeredView}>
                <TouchableWithoutFeedback>
                  <View style={theme.modalView}>
                    {modalType === 'Join' ? (
                      <>
                        <Text style={styles.modalText}>Enter Display Name</Text>
                        <TextInput
                          style={styles.modalInput}
                          value={displayName}
                          autoCorrect={false}
                          onChangeText={(text) => {
                            setDisplayName(text);
                          }}
                        />
                        <Text style={styles.modalText}>Enter Room Code</Text>
                        <TextInput
                          style={styles.modalInput}
                          value={roomCode}
                          autoCapitalize='characters'
                          autoCorrect={false}
                          onChangeText={(text: string) => {
                            setRoomCode(text.toUpperCase().substr(0, 6));
                            setError(null);
                          }}
                        />
                        {error && (
                          <Text style={styles.modalError}>{error}</Text>
                        )}
                      </>
                    ) : (
                      <>
                        <Text style={styles.modalText}>Enter Display Name</Text>
                        <TextInput
                          style={styles.modalInput}
                          value={displayName}
                          autoCorrect={false}
                          onChangeText={(text: string) => {
                            setError(null);
                            setDisplayName(text);
                          }}
                        />
                        {error && (
                          <Text style={styles.modalError}>{error}</Text>
                        )}
                      </>
                    )}
                    <View style={styles.modalButtons}>
                      <HeaderButton
                        label='Cancel'
                        style={[
                          styles.modalButton,
                          {
                            borderRightWidth: 0.5
                          }
                        ]}
                        onPress={() => {
                          setModalVisible(false);
                          setError(null);
                        }}
                      />
                      <HeaderButton
                        label={modalType}
                        style={[styles.modalButton]}
                        onPress={
                          modalType === 'Join'
                            ? handleJoinRoom
                            : handleCreateRoom
                        }
                      />
                    </View>
                  </View>
                </TouchableWithoutFeedback>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      )}
      <TouchableOpacity style={theme.button} onPress={pressCreate}>
        <Text>Create Room</Text>
      </TouchableOpacity>
      <TouchableOpacity style={theme.button} onPress={pressJoin}>
        <Text>Join Room</Text>
      </TouchableOpacity>
    </SafeAreaView>
  );
}
function IsValidDisplayName(name: string): boolean {
  return name.length > 0;
}

export default HomeScreen;

const styles = StyleSheet.create({
  modalText: {
    paddingTop: 20
  },
  modalError: {
    paddingBottom: 15,
    color: 'red',
    textAlign: 'center'
  },
  modalInput: {
    borderRadius: 5,
    backgroundColor: 'white',
    width: 150,
    padding: 7,
    margin: 15,
    textAlign: 'center'
  },
  modalButtons: {
    width: '100%',
    alignSelf: 'flex-start',
    flexDirection: 'row',
    bottom: 0
  },
  modalButton: {
    width: '50%',
    borderTopWidth: 0.5,
    borderColor: 'rgba(0, 0, 0, 0.1)'
  }
});
