import { MaterialIcons } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Alert,
  Image,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TouchableHighlight,
  TouchableOpacity,
  View
} from 'react-native';
import Checkbox from '../components/Checkbox';

import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppScreens, RootStackParamList } from '../types';

import BottomButton from '../components/BottomButton';
import BounceBadge from '../components/BounceBadge';
import layout from '../constants/Layout';
import { MpaaRatingFilter, Platform } from '../shared/movies';
import { API } from '../storage/Api';
import { Prefs } from '../storage/Preferences';
import { useSocket } from '../storage/SocketHook';
import { useUser } from '../storage/UserHook';
import theme from '../style';
import ModalAlert, {ModalAlertProps} from '../components/ModalAlert';
const { width } = layout.window;

type ConfigScreenRouteProps = RouteProp<RootStackParamList, AppScreens.Config>;

type ConfigScreenNavProps = StackNavigationProp<
  RootStackParamList,
  AppScreens.Config
>;

interface ConfigScreenProps {
  navigation: ConfigScreenNavProps;
  route: ConfigScreenRouteProps;
}
const ratingsBadges: { rating: MpaaRatingFilter }[] = [
  { rating: 'G' },
  { rating: 'PG' },
  { rating: 'PG-13' },
  { rating: 'R' }
];
const streamingPlatforms: { platform: Platform; img: any }[] = [
  { platform: 'netflix', img: require('../assets/images/netflix-wide.png') },
  // { platform: 'hulu', img: require('../assets/images/hulu-wide.png') },
  {
    platform: 'disneyplus',
    img: require('../assets/images/disneyplus-wide.jpg')
  },
  // { platform: 'hbomax', img: require('../assets/images/hbomax-wide.jpg') }
];

function ConfigScreen({ navigation, route }: ConfigScreenProps) {
  const { displayName } = route.params;
  const socketWrapper = useSocket();
  const [error, setError] = useState<string | null>(null);
  const [modalAlert, setModalAlert] = useState<ModalAlertProps | null>(null);
  const [loading, setLoading] = useState(false);

  const user = useUser();
  const [selectedRatings, setSelectedRatings] = useState<
    Record<MpaaRatingFilter, boolean>
  >({ G: true, PG: true, 'PG-13': true, R: true });

  const [selectedPlatforms, setSelectedPlatforms] = useState<
    Partial<Record<Platform, boolean>>
  >({ netflix: true, hulu: true, disneyplus: true, hbomax: true });

  useEffect(() => {
    Prefs.Platforms.get().then((platforms) => {
      if (platforms) setSelectedPlatforms(platforms);
    });
  }, [setSelectedPlatforms]);

  useEffect(() => {
    Prefs.MpaaRatings.get().then((ratings) => {
      if (ratings) setSelectedRatings(ratings);
    });
  }, [setSelectedRatings]);

  const renderRatingBadges = ratingsBadges.map((mpaa) => {
    const selected = selectedRatings[mpaa.rating];
    return (
      <BounceBadge
        component={TouchableHighlight}
        style={[
          styles.ratingBadge,
          { backgroundColor: selected ? 'white' : 'grey' }
        ]}
        onPress={() =>
          setSelectedRatings((r) => ({
            ...r,
            [mpaa.rating]: !selected
          }))
        }
        key={mpaa.rating}
        bounceFriction={0.5}
      >
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {!selected && (
            <MaterialIcons
              name='do-not-disturb-alt'
              size={30}
              color='red'
              style={{
                position: 'absolute',
                zIndex: 2,
                elevation: 2
              }}
            />
          )}
          <Text style={styles.ratingBadgeText}>{mpaa.rating}</Text>
        </View>
      </BounceBadge>
    );
  });

  const renderStreamingBadges = streamingPlatforms.map((platformData) => {
    const { platform, img } = platformData;
    return (
      <BounceBadge
        key={platform}
        component={TouchableOpacity}
        style={[styles.streamingBadge]}
        bounceFriction={10}
        onPress={() => {
          setSelectedPlatforms((s) => ({ ...s, [platform]: !s[platform] }));
        }}
      >
        <Image style={styles.streamingBadgeImage} source={img} />
        <Checkbox
          style={styles.streamingBadgeCheck}
          checked={selectedPlatforms[platform] || false}
          size={24}
          color='cyan'
        />
      </BounceBadge>
    );
  });

  const handleCreateRoom = async () => {
    setError(null);
    const ratings = ratingsBadges
      .filter((r) => selectedRatings[r.rating])
      .map((r) => r.rating);
    const platforms = streamingPlatforms
      .filter((p) => selectedPlatforms[p.platform])
      .map((p) => p.platform);
    if (ratings.length !== 0 && platforms.length !== 0) {
      setLoading(true);
      try {
        await Promise.all([
          Prefs.Platforms.set(selectedPlatforms),
          Prefs.MpaaRatings.set(selectedRatings)
        ]);
        const { code } = await API.CreateRoom(
          { ratings, platforms, timeoutSeconds: null },
          { token: await user!.getIdToken() }
        );
        const sock = await socketWrapper.JoinRoom(code, displayName);
        navigation.navigate(AppScreens.Waiting, {
          roomCode: sock.roomCode,
          displayName
        });
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('An unknown error occurred');
        }
        setLoading(false);
      }
    } else {
      setModalAlert({
        title: 'Invalid Settings',
        subtext: 'You must select at least one MPAA rating and one streaming platform',
        options: [{ label: 'Ok', onPress: () => {console.log("Dismissing modal"); setModalAlert(null)} }],
        onDismiss: () => setModalAlert(null),
      });
    }
  };

  return (
    <SafeAreaView style={theme.screen}>
      <ScrollView style={{ marginBottom: 40 }}>
        <View style={styles.section}>
          <Text>MPAA Rating</Text>
          <View style={styles.grid}>{renderRatingBadges}</View>
        </View>
        <View style={styles.section}>
          <Text>Streaming Platforms</Text>
          <View style={styles.grid}>{renderStreamingBadges}</View>
        </View>
        {loading && <ActivityIndicator size='large' />}
        {error && (
          <Text style={{ color: 'red', textAlign: 'center' }}>{error}</Text>
        )}
        {modalAlert && (
          <ModalAlert
            title={modalAlert.title}
            subtext={modalAlert.subtext}
            options={modalAlert.options}
            onDismiss={modalAlert.onDismiss}
          />
        )}
      </ScrollView>
      <BottomButton text='Create Room' onPress={handleCreateRoom} />
    </SafeAreaView>
  );
}

export default ConfigScreen;

const styles = StyleSheet.create({
  section: {
    padding: 20
  },
  grid: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  ratingBadge: {
    flex: 1,
    minWidth: width / 4 - 80,
    maxWidth: width / 2 - 30,
    height: 40,
    borderRadius: 20,
    margin: 5,
    paddingLeft: 15,
    paddingRight: 15
  },
  ratingBadgeText: {
    fontWeight: 'bold',
    textAlign: 'center'
  },
  streamingBadge: {
    flex: 1,
    height: 100,
    minWidth: 250,
    maxWidth: 250,
    margin: 10,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: 'black',
    borderRadius: 15
  },
  streamingBadgeImage: {
    resizeMode: 'cover',
    width: '100%',
    height: '100%',
    borderRadius: 15
  },
  streamingBadgeCheck: {
    position: 'absolute',
    top: 10,
    right: 10
  }
});
