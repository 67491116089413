import React from 'react';

// @ts-ignore
import Badge from './Badge';

function BounceBadge({ component, children, bounceFriction, ...props }) {
  return (
    <Badge component={component} {...props}>
      {children}
    </Badge>
  );
}

export default BounceBadge;
