import React from 'react';
import firebase from 'firebase/app';
import { User } from '../types';

const UserContext = React.createContext<User | null>(null);

export function UserProvider(props: { children: any, user: User }){
  const [user, setUser] = React.useState<User | null>(props.user);
  React.useEffect(() => {
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged((user) => {

        setUser(user);
      });
    return () => unsubscribe();
  }, []);
  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
}

export function useUser() {
  return React.useContext(UserContext);
}
