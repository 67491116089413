import React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';

function HeaderButton({ onPress, label, style, children }: any) {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={style ? [styles.button, ...style] : styles.button}
    >
      {children ? children : <Text style={styles.buttonLabel}>{label}</Text>}
    </TouchableOpacity>
  );
}

export default HeaderButton;

const styles = StyleSheet.create({
  button: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonLabel: {
    color: 'rgb(33, 150, 243)',
    fontSize: 16
  }
});
