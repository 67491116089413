import React from 'react';
import { StyleSheet } from 'react-native';

import { FontAwesome5, Ionicons } from '@expo/vector-icons';

interface CheckboxProps {
  checked: boolean;
  style?: any;
  size?: number;
  color?: string;
}

function Checkbox({ checked, style, size, color }: CheckboxProps) {
  return checked ? (
    <Ionicons
      style={style ? style : {}}
      name='ios-checkmark-circle'
      size={size}
      color={color}
    />
  ) : (
    <FontAwesome5
      style={style ? style : {}}
      name='circle'
      size={size}
      color={color}
    />
  );
}

export default Checkbox;
