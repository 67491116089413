import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDJsqNzq47SkN_4E33_pxUNzc-9gIDVDVQ',
  authDomain: 'cinema-swipe.firebaseapp.com',
  projectId: 'cinema-swipe',
  storageBucket: 'cinema-swipe.appspot.com',
  databaseURL: 'https://cinema-swipe.firebaseio.com',
  messagingSenderId: '252746202953',
  appId: '1:252746202953:web:1ae8d439814e707fcae498'
};

let app: firebase.app.App;
if (firebase.apps.length === 0) app = firebase.initializeApp(firebaseConfig);
else app = firebase.app();

export const firestore = app.firestore();
export const auth = firebase.auth;
