import React, { useEffect, useState } from 'react';
import * as AppleAuthentication from 'expo-apple-authentication';
import * as Crypto from 'expo-crypto';
import { auth } from '../storage/firebase';

export function SignInWithApple() {
  const [isAvailable, setIsAvailable] = useState(false);
  useEffect(() => {
    AppleAuthentication.isAvailableAsync().then(setIsAvailable);
  }, [setIsAvailable]);
  return (
    <>
      {isAvailable && (
        <AppleAuthentication.AppleAuthenticationButton
          buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
          buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
          cornerRadius={5}
          style={{ width: 197, height: 44 }}
          onPress={async () => {
            try {
              const nonce = Math.random().toString(36).substring(2, 10);
              const hashedNonce = await Crypto.digestStringAsync(
                Crypto.CryptoDigestAlgorithm.SHA256,
                nonce
              );
              const cred = await AppleAuthentication.signInAsync({
                requestedScopes: [
                  AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                  AppleAuthentication.AppleAuthenticationScope.EMAIL
                ],
                nonce: hashedNonce
              });
              const provider = new auth.OAuthProvider('apple.com');
              const credential = provider.credential({
                idToken: cred.identityToken!,
                rawNonce: nonce
              });
              await auth().signInWithCredential(credential);
              // signed in
            } catch (e) {
                console.error(e);
            //   if (e.code === 'ERR_CANCELED') {
            //     // handle that the user canceled the sign-in flow
            //   } else {
            //     // handle other errors
            //   }
            }
          }}
        />
      )}
    </>
  );
}
