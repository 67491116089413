import React from 'react';
import { StyleSheet, TouchableHighlight, Text, View } from 'react-native';

interface BottomButtonProps {
  text: string;
  onPress?: () => void;
  disabled?: boolean;
}

function BottomButton({ text, onPress, disabled }: BottomButtonProps) {
  return (
    <TouchableHighlight
      style={styles.button}
      onPress={onPress}
      disabled={disabled || false}
    >
      <Text style={styles.label}>{text}</Text>
    </TouchableHighlight>
  );
}

export default BottomButton;

const styles = StyleSheet.create({
  button: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    padding: 20,
    backgroundColor: 'white'
  },
  label: {
    // color: '',
    textTransform: 'uppercase',
    fontWeight: 'bold'
  }
});
