import { io, Socket } from 'socket.io-client';
import {
  ServerMessage,
  ClientMessage,
  ClientMessageType,
  ServerMessageType
} from '../shared/messages';
import {Movie} from '../shared/movies'
import { User } from '../types';
import { API } from './Api';
const MAX_CONNECT_ATTEMPTS = 2;
type Dispose = () => void;

export class ClientSocket {
  static async Join(
    user: User,
    roomCode: string,
    displayName: string
  ): Promise<ClientSocket> {
    const token = await user.getIdToken();
    const hostURL = await API.GetHostURL();
    const sockURL = hostURL.replace('http', 'ws');
    const sock = io(sockURL, {
      auth: { bearer: token },
      query: { request: 'join', roomCode, displayName }
    });
    return await new Promise<ClientSocket>((resolve, reject) => {
      let reconnectionAttempts = 0;
      sock.on('connect_error', (e) => {
        if (e.message === 'xhr poll error') {
          if (reconnectionAttempts++ >= MAX_CONNECT_ATTEMPTS)
            reject(new Error('Could not connect to server'));
        }
      });
      sock.once('message', (msg: ServerMessage) => {
        if (msg.type === ServerMessageType.ROOM_JOINED) {
          resolve(
            new ClientSocket({
              isOwner: msg.isOwner,
              roomCode: msg.roomCode,
              sock,
              currentMembers: msg.currentMembers
            })
          );
        } else if (msg.type === ServerMessageType.ERROR) {
          sock.close();
          reject(new Error(msg.message));
        }
      });
    });
  }

  private _sock: Socket;
  readonly roomCode: string;
  readonly IsOwner: boolean;

  private _onMemberCallback:
    | ((id: number, name: string, action: 'joined' | 'left') => void)
    | undefined;
  private _onRoomStartedCallback: (() => void) | undefined;
  private _onMatchCallback: ((id: number) => void) | undefined;
  private _onMoviesQueued:
    | ((movies: Movie[], position: 'front' | 'back') => void)
    | undefined;
  private _onDisconnect: ((reason: string) => void) | undefined;
  private _onConnect: (() => void) | undefined;
  // Map from id to display name
  readonly currentMembers: Record<number, string>;

  private constructor(args: {
    isOwner: boolean;
    roomCode: string;
    sock: Socket;
    currentMembers: Record<number, string>;
  }) {
    this.roomCode = args.roomCode;
    this.IsOwner = args.isOwner;
    this.currentMembers = args.currentMembers;
    this._sock = args.sock;
    this.handleSocket();
  }
  private handleSocket() {
    this._sock.on('disconnect', (reason) => {
      if (this._onDisconnect) this._onDisconnect(reason);
    });
    this._sock.on('connect', () => {
      if (this._onConnect) this._onConnect();
    });
    this._sock.on('message', (msg: ServerMessage) => {
      switch (msg.type) {
        case ServerMessageType.ROOMMATE_JOINED:
          this.currentMembers[msg.info.id] = msg.info.name;
          if (this._onMemberCallback)
            this._onMemberCallback(msg.info.id, msg.info.name, 'joined');
          break;
        case ServerMessageType.ROOMMATE_LEFT:
          delete this.currentMembers[msg.info.id];
          if (this._onMemberCallback)
            this._onMemberCallback(msg.info.id, msg.info.name, 'left');
          break;
        case ServerMessageType.ROOM_STARTED:
          if (this._onRoomStartedCallback) this._onRoomStartedCallback();
          break;
        case ServerMessageType.ROOM_MATCHED:
          if (this._onMatchCallback) this._onMatchCallback(msg.info.id);
          break;
        case ServerMessageType.QUEUE_MOVIES:
          if (this._onMoviesQueued)
            this._onMoviesQueued(msg.movies, msg.position);
          break;
      }
    });
  }

  OnDisconnect(cb: (reason: string) => void): Dispose {
    this._onDisconnect = cb;
    return () => (this._onDisconnect = undefined);
  }

  OnConnected(cb: () => void): Dispose {
    this._onConnect = cb;
    return () => (this._onConnect = undefined);
  }

  OnMemberUpdate(
    cb: (id: number, name: string, action: 'joined' | 'left') => void
  ): Dispose {
    this._onMemberCallback = cb;
    return () => (this._onMemberCallback = undefined);
  }

  StartRoom() {
    if (!this.IsOwner) return;
    const msg: ClientMessage = { type: ClientMessageType.START_ROOM };
    this._sock.send(msg);
  }

  OnRoomStarted(cb: () => void) {
    this._onRoomStartedCallback = () => cb();
    return () => (this._onRoomStartedCallback = undefined);
  }

  OnMatch(cb: (id: number) => void) {
    this._onMatchCallback = (id) => cb(id);
    return () => (this._onMatchCallback = undefined);
  }

  OnMoviesQueued(cb: (movies: Movie[], position: 'front' | 'back') => void) {
    this._onMoviesQueued = (m, p) => cb(m, p);
    return () => (this._onMoviesQueued = undefined);
  }

  Disconnect() {
    this._sock.disconnect();
  }

  Swipe(movie: Movie, direction: 'right' | 'left') {
    const msg: ClientMessage = {
      type: ClientMessageType.SWIPE,
      direction,
      movieId: movie.id
    };
    this._sock.send(msg);
  }

  private _getMoreMoviesRequestInFlight = false;
  RequestMoreMovies(): Promise<Movie[]> {
    return new Promise((resolve) => {
      if (this._getMoreMoviesRequestInFlight) {
        resolve([]);
        return;
      }
      this._getMoreMoviesRequestInFlight = true;
      const currentCallback = this._onMoviesQueued;
      this._onMoviesQueued = (movies) => {
        this._onMoviesQueued = currentCallback;
        console.log(movies);
        this._getMoreMoviesRequestInFlight = false;
        resolve(movies);
      };

      this._sock.emit('message', { type: ClientMessageType.REQUEST_MOVIES });
    });
  }
}
