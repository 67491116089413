import { Asset } from 'expo-asset';
import { Image } from 'react-native';

export default function cacheAssetsAsync({
  images = []
}: {
  images: string[] | NodeRequire[];
}) {
  return Promise.all([...cacheImages(images)]);
}

function cacheImages(images: string[] | NodeRequire[]) {
  return images.map((image: string | NodeRequire) => {
    console.log('caching asset: ', image);
    if (typeof image === 'string') {
      return Image.prefetch(image);
    } else {
      return Asset.fromModule(image).downloadAsync();
    }
  });
}
