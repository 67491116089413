import firebase from 'firebase/app';
import 'firebase/firestore';

import { MpaaRatingFilter, Platform } from '../shared/movies';
import { firestore } from './firebase';

enum StorageKeys {
  DISPLAY_NAME = 'displayName',
  MPAA_RATINGS = 'mpaaRatings',
  PLATFORMS = 'savedPlatforms'
}

const UserPrefRef = firestore.collection('UserPreferences');

async function GetDisplayName(): Promise<string | null> {
  const doc = await UserPrefRef.doc(firebase.auth().currentUser!.uid).get();
  const data = doc.data();
  if (data) return data[StorageKeys.DISPLAY_NAME] || null;
  else return null;
}

function SetDisplayName(name: string): Promise<void> {
  return UserPrefRef.doc(firebase.auth().currentUser!.uid).set(
    { [StorageKeys.DISPLAY_NAME]: name },
    { merge: true }
  );
}

async function GetRatings(): Promise<Record<MpaaRatingFilter, boolean> | null> {
  const doc = await UserPrefRef.doc(firebase.auth().currentUser!.uid).get();
  const data = doc.data();
  return data ? data[StorageKeys.MPAA_RATINGS] || null : null;
}

function SetRatings(
  mpaaRatings: Record<MpaaRatingFilter, boolean>
): Promise<void> {
  return UserPrefRef.doc(firebase.auth().currentUser!.uid).set(
    { [StorageKeys.MPAA_RATINGS]: mpaaRatings },
    { merge: true }
  );
}

async function GetPlatforms(): Promise<Partial<
  Record<Platform, boolean>
> | null> {
  const doc = await UserPrefRef.doc(firebase.auth().currentUser!.uid).get();
  const data = doc.data();
  return data ? data[StorageKeys.PLATFORMS] || null : null;
}

function SetPlatforms(platforms: Partial<Record<Platform, boolean>>) {
  return UserPrefRef.doc(firebase.auth().currentUser!.uid).set(
    { [StorageKeys.PLATFORMS]: platforms },
    { merge: true }
  );
}

export const Prefs = {
  DisplayName: { get: GetDisplayName, set: SetDisplayName },
  MpaaRatings: { get: GetRatings, set: SetRatings },
  Platforms: { get: GetPlatforms, set: SetPlatforms }
};
