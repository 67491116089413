import * as Device from 'expo-device';
import { Platform, StyleSheet } from 'react-native';

const theme = StyleSheet.create({
  screen: {
    flex: 1
  },
  centerScreen: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  fullScreen: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'stretch'
  },
  button: {
    margin: 5,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 20,
    paddingLeft: 20,
    borderRadius: 20,
    backgroundColor: 'white',
    textAlign: 'center'
  },
  modalOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: Platform.OS === "web"  && __DEV__ ? '100vw' : undefined,
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: '#f5f5f5',
    borderRadius: 20,
    width: 220,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  modalText: {
    paddingTop: 20,
    fontWeight: 'bold',
    fontSize: 15
  },
  modalInput: {
    borderRadius: 5,
    backgroundColor: 'white',
    width: 150,
    padding: 7,
    margin: 20,
    marginTop: 15,
    textAlign: 'center'
  },
  modalButtons: {
    width: '100%',
    alignSelf: 'flex-start',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    bottom: 0
  },
  modalButton: {
    width: '50%',
    borderTopWidth: 0.5,
    borderColor: 'rgba(0, 0, 0, 0.1)'
  }
});

export default theme;
