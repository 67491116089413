import React from 'react';
import {
  SafeAreaView, StyleSheet, Text
} from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';

import { auth } from '../storage/firebase';
import theme from '../style/index';
import { AppScreens, RootStackParamList } from '../types';

type SettingsScreenNavProps = StackNavigationProp<
  RootStackParamList,
  AppScreens.Settings
>;

interface SettingsScreenProps {
  navigation: SettingsScreenNavProps;
}

function SettingsScreen({ navigation }: SettingsScreenProps) {
  return (
    <SafeAreaView style={theme.centerScreen}>
      {/*auth().currentUser?.isAnonymous && (
        <Text style={styles.settingText}>Login with Google</Text>
      )*/}
      <Text
        style={[styles.logoutText, styles.settingText]}
        onPress={() => {
          auth().signOut();
          navigation.pop();
        }}
      >
        Logout
      </Text>
    </SafeAreaView>
  );
}

export default SettingsScreen;

const styles = StyleSheet.create({
  logoutText: {
    color: '#ff3a30'
  },
  settingText: {
    padding: 10
  }
});
