import React from 'react';
// @ts-ignore
import Icon from '@expo/vector-icons/FontAwesome';
import { StyleSheet, TouchableOpacity } from 'react-native';

type IconButton = {
  name: string;
  color: string;
  size: number;
  onPress: () => void;
};

interface IconButtonProps {
  button: IconButton;
}

function IconButton(props: IconButtonProps) {
  const { name, color, onPress, ...otherProps } = props.button;
  return (
    <TouchableOpacity style={[styles.button]} onPress={onPress} {...otherProps}>
      <Icon name={name} color={color} size={35} />
    </TouchableOpacity>
  );
}

export default IconButton;

const styles = StyleSheet.create({
  button: {
    backgroundColor: 'white',
    padding: 10,
    width: 60,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 30,
    shadowColor: 'black',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowRadius: 6,
    shadowOpacity: 0.1,
    elevation: 2
  }
});
