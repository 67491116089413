import { Ionicons } from '@expo/vector-icons';
import * as Clipboard from 'expo-clipboard';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  ActivityIndicator,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TouchableHighlight,
  View
} from 'react-native';

import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { AppScreens, RootStackParamList } from '../types';
import Badge from '../components/Badge';
import BottomButton from '../components/BottomButton';
import BounceBadge from '../components/BounceBadge';
import ModalAlert from '../components/ModalAlert';
import { useSocket } from '../storage/SocketHook';
import { ClientSocket } from '../storage/SwipeSocket';
import theme from '../style';
import HeaderButton from '../components/HeaderButton';
import ModalAlertButton from '../components/ModalAlertButton';

type WaitingRoomRouteProps = RouteProp<RootStackParamList, AppScreens.Waiting>;

type WaitingRoomNavProps = StackNavigationProp<
  RootStackParamList,
  AppScreens.Waiting
>;

interface WaitingRoomProps {
  route: WaitingRoomRouteProps;
  navigation: WaitingRoomNavProps;
}

const MAX = 30;

function WaitingRoom({ route, navigation }: WaitingRoomProps) {
  const { roomCode, displayName } = route.params;
  const socketWrapper = useSocket();
  const [socket, setSocket] = useState(socketWrapper.GetSocket());
  useEffect(() => {
    socketWrapper
      .JoinRoom(roomCode, displayName)
      .then((s) => setSocket(s))
      .catch((err) => {
        console.error(err);
        navigation.replace(AppScreens.Home);
      });
  }, [socketWrapper, navigation]);
  if (socket) {
    return (
      <WaitingRoomConnected
        socket={socket}
        navigation={navigation}
        displayName={displayName}
      />
    );
  } else {
    return (
      <View>
        <Text>Loading...</Text>
      </View>
    );
  }
}

interface WaitingRoomComponentProps {
  socket: ClientSocket;
  displayName: string;
  navigation: WaitingRoomNavProps;
}

function WaitingRoomConnected({
  socket,
  navigation,
  displayName
}: WaitingRoomComponentProps) {
  const [members, setMembers] = useState(socket.currentMembers || {});
  const socketWrapper = useSocket();
  useEffect(() => {
    const token = socket.OnMemberUpdate((id, name, action) => {
      setMembers((m) => {
        if (action === 'joined') return { ...m, [id]: name };
        else {
          delete m[id];
          return { ...m };
        }
      });
    });
    return token;
  }, [setMembers, socket]);

  useEffect(() => {
    const token = socket.OnRoomStarted(() => {
      navigation.replace(AppScreens.Swipe, {
        roomCode: socket.roomCode,
        displayName
      });
    });
    return token;
  }, [socket, navigation]);

  const renderMemberBadges = Object.keys(members).map((id) => (
    <Badge key={id}>
      <Text>{members[parseInt(id)]}</Text>
    </Badge>
  ));

  const leaveRoom = () => {
    socketWrapper.LeaveRoom();
    navigation.popToTop();
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeftContainerStyle: { width: '100%' },
      headerLeft: () => {
        return (
          <ModalAlertButton
            title={socket.IsOwner ? 'Close Room' : 'Leave Room'}
            modal={{
              title: socket.IsOwner ? 'Close Room' : 'Leave Room',
              subtext: socket.IsOwner
                ? 'This will close the room for all members'
                : 'Leaving will bring you to the Home Page',
              options: [{label: 'Yes', onPress: leaveRoom}, {label: 'No'}]
            }}
          />
        );
      }
    });
  }, [navigation]);

  const copyCode = () => {
    Clipboard.setStringAsync(socket.roomCode);
  };

  return (
    <SafeAreaView style={theme.centerScreen}>
      <View style={styles.status}>
        <BounceBadge
          component={TouchableHighlight}
          bounceFriction={2}
          onPress={() => copyCode()}
          style={[styles.roomCodeBadge]}
        >
          <Text style={styles.roomCodeText}>Room Code: {socket.roomCode}</Text>
        </BounceBadge>
        {Object.keys(members).length !== MAX ? (
          <>
            <Text>Waiting for others to join...</Text>
            <ActivityIndicator style={{ margin: 20 }} size='large' />
          </>
        ) : (
          <>
            <Text>
              Room is Full!{' '}
              {socket.IsOwner ? '' : 'Waiting for host to start...'}
            </Text>
            <Ionicons
              style={{ margin: 20 }}
              name='ios-checkmark-circle'
              size={32}
              color={'rgb(33, 150, 243)'}
            />
          </>
        )}
        <Text>
          {Object.keys(members).length}/{MAX}
        </Text>
      </View>
      <View style={styles.listWrapper}>
        <ScrollView contentContainerStyle={styles.list}>
          {renderMemberBadges}
        </ScrollView>
      </View>
      {socket.IsOwner ? (
        <BottomButton
          text='Press to Start'
          onPress={() => socket.StartRoom()}
        />
      ) : (
        <BottomButton
          text={`Room Code: ${socket.roomCode}`}
          onPress={copyCode}
        />
      )}
    </SafeAreaView>
  );
}

export default WaitingRoom;

const styles = StyleSheet.create({
  roomCodeBadge: {
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 30
  },
  roomCodeText: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 25,
    textAlign: 'center'
  },
  status: {
    alignItems: 'center',
    padding: 10
  },
  listWrapper: {
    flex: 1,
    width: '100%',
    padding: 10
  },
  list: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center'
  }
});
