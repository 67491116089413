import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { LinearGradient } from 'expo-linear-gradient';
import * as Linking from 'expo-linking';
import React, { useLayoutEffect } from 'react';
import {
  Image, SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View
} from 'react-native';

import BounceBadge from '../components/BounceBadge';
import HeaderButton from '../components/HeaderButton';
import MovieCard from '../components/MovieCard';
import Layout from '../constants/Layout';
import { Platform, PlatformData } from '../shared/movies';
import { API } from '../storage/Api';
import { useUser } from '../storage/UserHook';
import theme from '../style';
import { AppScreens, RootStackParamList } from '../types';

type MatchesScreenRouteProps = RouteProp<
  RootStackParamList,
  AppScreens.Matches
>;

type MatchesScreenNavProps = StackNavigationProp<
  RootStackParamList,
  AppScreens.Matches
>;

interface MatchesScreenProps {
  route: MatchesScreenRouteProps;
  navigation: MatchesScreenNavProps;
}

function loadLogo(platform: Platform) {
  switch (platform) {
    case 'netflix':
      return require('../assets/images/netflix-wide.png');
    case 'hulu':
      return require('../assets/images/hulu-wide.png');
    case 'disneyplus':
      return require('../assets/images/disneyplus-wide.jpg');
    case 'hbonow':
      return require('../assets/images/hbonow-wide.png');
    case 'hbomax':
      return require('../assets/images/hbomax-wide.jpg');
  }
}

function getUrl(platform: Platform, id: string): string {
  switch (platform) {
    case 'disneyplus':
      return `https://disneyplus.com/movies/title/${id}`;
    case 'netflix':
      return `https://netflix.com/watch/${id}`;
    case 'hulu':
      return `https://hulu.com/watch/${id}`;
    case 'hbomax':
      return `https://play.hbomax.com/page/urn:hbo:page:${id}:type:feature`;
  }
  return 'NOT FOUND';
}

function MatchesScreen({ route, navigation }: MatchesScreenProps) {
  const { movie, roomCode } = route.params;
  const user = useUser();
  const leaveRoom = () => {
    navigation.popToTop();
    navigation.replace(AppScreens.Home);
  };
  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => {
        return (
          <HeaderButton
            label='Home'
            style={[{ height: '100%' }]}
            onPress={leaveRoom}
          />
        );
      }
    });
  }, [navigation]);

  const renderPlatforms = movie.platforms
    ? movie.platforms.map((platform: PlatformData) => {
        return (
          <BounceBadge
            component={TouchableOpacity}
            bounceFriction={0.5}
            key={platform.platform}
            onPress={() =>
              Linking.openURL(getUrl(platform.platform, platform.id))
                .then(() => user?.getIdToken())
                .then((token) => API.RecordClickthrough(roomCode, movie.id, {token: token!}))
                .catch(console.error)
            }
            style={[styles.availabilityBadge]}
          >
            <Image
              source={loadLogo(platform.platform)}
              style={styles.availabilityBadgeImage}
            />
          </BounceBadge>
        );
      })
    : null;
  return (
    <SafeAreaView style={[theme.fullScreen]}>
      <LinearGradient colors={['#e6823c', '#a53ce6']} style={styles.gradient} />
      <ScrollView>
        <View style={styles.resultWrapper}>
          <View style={styles.matchWrapper}>
            <MovieCard
              card={{ ...movie }}
              tapped={false}
              index={0}
              focused={true}
            />
          </View>
          <View style={styles.availabilityWrapper}>
            <Text>Watch now on</Text>
            <View style={styles.badges}>{renderPlatforms}</View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

export default MatchesScreen;

const styles = StyleSheet.create({
  gradient: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    minHeight: Layout.window.height
  },
  resultWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minWidth: Layout.window.width,
    minHeight: Layout.window.height,
    marginTop: 50
  },
  matchWrapper: {
    flex: 10
  },
  availabilityWrapper: {
    padding: 30,
    flex: 1,
    textAlign: 'center'
  },
  badges: {
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  availabilityBadge: {
    flex: 1,
    height: 100,
    maxWidth: 200,
    margin: 10,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: 'black',
    borderRadius: 15
  },
  availabilityBadgeImage: {
    width: '100%',
    height: '100%',
    borderRadius: 15
  }
});
