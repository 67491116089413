import React from 'react';
import { ClientSocket } from './SwipeSocket';
import { User } from '../types';

class SocketWrapper {
  private _sock: ClientSocket | null = null;
  private _user: User | null;
  constructor(user: User | null) {
    this._user = user;
  }
  public GetSocket(): ClientSocket | null {
    return this._sock;
  }
  public async JoinRoom(
    roomCode: string,
    displayName: string
  ): Promise<ClientSocket> {
    if (this._sock && this._sock.roomCode === roomCode) return this._sock;
    this._sock = await ClientSocket.Join(this._user!, roomCode, displayName);
    return this._sock;
  }
  public async LeaveRoom(){
    this._sock?.Disconnect();
    this._sock = null;
  }
}

const SocketContext = React.createContext(new SocketWrapper(null));

export function SocketProvider(props: { children: any; user: User }) {
  const [sock, setSock] = React.useState<SocketWrapper>(
    new SocketWrapper(props.user)
  );
  return (
    <SocketContext.Provider value={sock}>
      {props.children}
    </SocketContext.Provider>
  );
}

export function useSocket() {
  return React.useContext(SocketContext);
}
