import {Movie, MpaaRatingFilter, Platform} from './movies.js';

export type RoomConfig = {
  ratings: MpaaRatingFilter[];
  platforms: Platform[];
  timeoutSeconds: number | null;
};

export enum ServerMessageType {
  ROOM_STARTED = 'ServerMessageType_ROOM_STARTED',
  ROOM_JOINED = 'ServerMessageType_ROOM_JOINED',
  ROOMMATE_JOINED = 'ServerMessageType_ROOMMATE_JOINED',
  ROOMMATE_LEFT = 'ServerMessageType_ROOMMATE_LEFT',
  ROOM_CLOSED = 'ServerMessageType_ROOM_CLOSED',
  ROOM_MATCHED = 'ServerMessageType_ROOM_MATCHED',
  QUEUE_MOVIES = 'ServerMessageType_QUEUE_MOVIES',
  ERROR = 'ServerMessageType_ERROR',
}

export enum ClientMessageType {
  START_ROOM = 'ClientMessageType_START_ROOM',
  LEAVE_ROOM = 'ClientMessageType_LEAVE_ROOM',
  SWIPE = 'ClientMessageType_SWIPE',
  REQUEST_MOVIES = 'ClientMessageType_REQUEST_MOVIES',
}

type RoomId = {
  roomId: number;
};

type QueueMovies = {
  type: ServerMessageType.QUEUE_MOVIES;
  position: 'front' | 'back';
  movies: Movie[];
};

type RoomJoined = {
  type: ServerMessageType.ROOM_JOINED;
  isOwner: boolean;
  roomId: number;
  roomCode: string;
  currentMembers: Record<number, string>;
};

type StartRoom = {
  type: ClientMessageType.START_ROOM;
};
type LeaveRoom = {
  type: ClientMessageType.LEAVE_ROOM;
};
type Swipe = {
  type: ClientMessageType.SWIPE;
  direction: 'left' | 'right';
  movieId: number;
};
type RequestMovies = {
  type: ClientMessageType.REQUEST_MOVIES;
};

type RoomStarted = RoomId & {
  type: ServerMessageType.ROOM_STARTED;
};

type RoommateJoined = {
  type: ServerMessageType.ROOMMATE_JOINED;
  info: {name: string; id: number};
};
type RoommateLeft = {
  type: ServerMessageType.ROOMMATE_LEFT;
  info: {name: string; id: number};
};

type RoomClosed = {
  type: ServerMessageType.ROOM_CLOSED;
};

type RoomMatched = {
  type: ServerMessageType.ROOM_MATCHED;
  info: {id: number};
};

type Error = {
  type: ServerMessageType.ERROR;
  message: string;
};

export type ClientMessage = StartRoom | LeaveRoom | Swipe | RequestMovies;

export type ServerMessage =
  | Error
  | RoommateJoined
  | RoomJoined
  | RoommateLeft
  | RoomStarted
  | RoomClosed
  | QueueMovies
  | RoomMatched;
