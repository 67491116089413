import { Movie } from '../shared/movies';

export class MovieQueue {
  private _seen = new Set<number>();
  private _queue: Movie[] = [];
  private _onMoviePushed: (() => void) | undefined = undefined;
  public Push(movie: Movie | Movie[]): number {
    if (!Array.isArray(movie)) {
      movie = [movie]
    }
    const newMovies = movie.filter((m) => !this._seen.has(m.id));
    newMovies.forEach((m) => this._seen.add(m.id));
    this._queue.push(...newMovies);
    if (this._onMoviePushed && newMovies.length > 0) this._onMoviePushed();
    return newMovies.length;
  }

  public PushFront(movie: Movie | Movie[]): number {
    if (!Array.isArray(movie)) {
      movie = [movie]
    }
    const newMovies = movie.filter((m) => !this._seen.has(m.id));
    newMovies.forEach((m) => this._seen.add(m.id));
    this._queue = [...newMovies, ...this._queue]
    if (this._onMoviePushed && newMovies.length > 0) this._onMoviePushed();
    return newMovies.length;
  }

  public Length(): number {
    return this._queue.length;
  }
  public PopAsync(n: number): Promise<Movie[]> {
    const movies = this.Pop(n);
    if (movies.length > 0) return new Promise((resolve) => resolve(movies));
    return new Promise<Movie[]>((resolve, reject) => {
      this._onMoviePushed = () => {
        resolve(this.Pop(n));
      };
    });
  }

  Pop(): Movie | undefined;
  Pop(n: number): Movie[];
  Pop(n?: number): Movie[] | Movie | undefined {
    if (n === undefined) {
      return this._queue.shift();
    }
    const returnArray = this._queue.slice(0, n);
    this._queue = this._queue.slice(n);
    return returnArray;
  }
}
